import { logoutAdmin } from './fetch'

export function checkAuth(res: Response) {
  if (res.status === 401) {
    logoutAdmin().then(() => {
      window.location.reload()
    })
  }
  return res
}
